import React from "react";
import "./index.less";

const IconFont = Loader.loadBaseComponent("IconFont");
export default class MesRefresh extends React.Component {
  FreShen = () => {
    this.props.FreShen(true, true);
  };
  render() {
    const { total, isCover, activeKey } = this.props;
    return (
      <div className="community-header-total">
        <div className="total">
          {isCover
            ? (activeKey == 1
              ? "常住人员总数"
              : activeKey == 2
              ? "长期未出现人员总数"
              : "全部人员总数")
            : (activeKey == 1
            ? "偶尔出现人员总数"
            : activeKey == 2
            ? "频繁出现人员总数"
            : "全部人员总数")}
          ：<span className="number">{total ? total : 0}</span>
        </div>
        <div className="header-btn" onClick={this.FreShen}>
          <IconFont type={"icon-S_View_RotateRight"} theme="outlined" />
          刷新
        </div>
      </div>
    );
  }
}
